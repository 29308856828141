import React, { useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { Button } from "./button";
import { Spinner, InputGroup } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { useProject } from "../project";

export const TemplatesList = () => {
  const navigate = useNavigate();
  const project = useProject();
  const { getTemplates } = useApi();
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sizeFilter, setSizeFilter] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 15,
    current_page: 1,
    last_page: 1,
  });

  const fetchData = async (page = 1, searchTerm = "") => {
    try {
      setListLoading(true);
      const templatesData = await getTemplates(
        page,
        searchTerm,
        "id,title,thumbnail,size",
        sizeFilter
      );
      setTemplates(templatesData.data);
      setPagination({
        total: templatesData.total,
        per_page: templatesData.per_page,
        current_page: templatesData.current_page,
        last_page: templatesData.last_page,
      });
    } catch (error) {
      console.error("Erro ao buscar templates:", error);
    } finally {
      setListLoading(false);
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pagination.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    // Reseta memória do builder
    project.clear();
    fetchData(currentPage, searchTerm);
  }, [currentPage, sizeFilter]);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm.length < 3) {
        setFeedbackMessage(
          "Digite pelo menos 3 caracteres para realizar a busca."
        );
      } else {
        setFeedbackMessage("");
        fetchData(1, searchTerm);
      }
    }
  };

  const handleTemplateClick = async (templateId) => {
    navigate(`/app?template_id=${templateId}`);
  };

  const handleCreateNewBlankProject = () => {
    navigate("/app");
  };

  const renderSkeleton = () => {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
        {Array.from({ length: 15 }).map((_, index) => (
          <div
            key={index}
            className="p-4 rounded-md shadow-md bg-dark-250 animate-pulse"
          >
            <div className="w-full h-48 mb-2 rounded-md bg-dark-200"></div>
            <div className="h-6 rounded-md bg-dark-200"></div>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4 mb-4">
        <input
          type="text"
          placeholder="Pesquisar templates..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value.length < 3) {
              setFeedbackMessage(
                `Digite mais ${
                  3 - e.target.value.length
                } caracteres para buscar.`
              );
            } else {
              setFeedbackMessage("");
            }
          }}
          onKeyDown={handleSearchKeyDown}
          className="flex-1 p-2 border rounded-md gray-600 bg-dark-250 text-light-0 border-dark-250"
        />

        <Button
          text="Criar Layout em Branco"
          type="button"
          className="text-center"
          size="small"
          variant="primary"
          semibold
          icon="new-page"
          onClick={handleCreateNewBlankProject}
        />
      </div>

      {feedbackMessage && (
        <div className="mt-2 text-sm text-red-500">{feedbackMessage}</div>
      )}

      {/* Banner Section */}
      <div
        className="p-6 mb-4 text-white rounded-md bg-gradient-to-r from-primary-500 to-primary-700"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h2 className="mb-2 text-2xl font-bold">
          Encontre o Template Perfeito
        </h2>
        <p className="text-lg">
          Crie seu design com facilidade usando nossos templates profissionais.
          Ou inicie um projeto do zero!
        </p>
      </div>

      <div className="flex flex-col items-center justify-center gap-6">
        <div className="flex gap-6">
          <button
            className={`w-48 flex flex-col items-center gap-2 p-4 rounded-md shadow-md cursor-pointer transition duration-300 ${
              sizeFilter === "1080x1920"
                ? "bg-primary-500 text-white"
                : "bg-dark-250 text-light-0"
            }`}
            onClick={() => {
              setSizeFilter("1080x1920");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <rect
                x="6"
                y="2"
                width="12"
                height="20"
                rx="2"
              />
            </svg>
            <span className="text-sm font-semibold">Stories</span>
            <span className="text-xs">(1080x1920)</span>
          </button>

          <button
            className={`w-48 flex flex-col items-center gap-2 p-4 rounded-md shadow-md cursor-pointer transition duration-300 ${
              sizeFilter === "1080x1080"
                ? "bg-primary-500 text-white"
                : "bg-dark-250 text-light-0"
            }`}
            onClick={() => {
              setSizeFilter("1080x1080");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <rect
                x="4"
                y="4"
                width="16"
                height="16"
                rx="2"
              />
            </svg>
            <span className="text-sm font-semibold">Feed</span>
            <span className="text-xs">(1080x1080)</span>
          </button>

          <button
            className={`w-48 flex flex-col items-center gap-2 p-4 rounded-md shadow-md cursor-pointe transition duration-300 ${
              sizeFilter === ""
                ? "bg-primary-500 text-white"
                : "bg-dark-250 text-light-0"
            }`}
            onClick={() => {
              setSizeFilter("");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 8h16M4 16h16M4 12h16"
              />
            </svg>
            <span className="text-sm font-semibold">Todos</span>
            <span className="text-xs">(Todos os tamanhos)</span>
          </button>
        </div>
      </div>

      {listLoading ? (
        renderSkeleton()
      ) : templates.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
          {templates.map((template) => (
            <div
              key={template.id}
              className="p-4 rounded-md shadow-md cursor-pointer bg-dark-250"
              onClick={() => handleTemplateClick(template.id)}
            >
              <img
                src={template.thumbnail}
                alt={template.title}
                className="w-auto mx-auto mb-2 rounded-md max-h-48"
              />
              <h3 className="font-semibold text-md text-light-0">
                {template.title}
              </h3>

              <p className="text-xs text-gray-400">{template.size}</p>

              <div className="flex items-center gap-2 mt-2">
                <img
                  src={template.author?.avatar || "/logos/perfil-braip.webp"}
                  alt={template.author?.name || "Braip"}
                  className="w-6 h-6 border border-gray-500 rounded-full"
                />
                <span className="text-xs text-gray-300">
                  {template.author?.name || "Equipe Braip"}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 text-center rounded-md bg-dark-250 text-light-500">
          Nenhum template encontrado.
        </div>
      )}

      <div className="flex justify-between mt-4">
        <Button
          text="Anterior"
          type="button"
          className="text-center"
          size="small"
          variant="primary"
          semibold
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        />
        <span>
          Página {currentPage} de {pagination.last_page}
        </span>
        <Button
          text="Próxima"
          type="button"
          className="text-center"
          size="small"
          variant="primary"
          semibold
          onClick={handleNextPage}
          disabled={currentPage === pagination.last_page}
        />
      </div>
    </div>
  );
};
