import { useState } from "react";
import { Link } from "react-router-dom";
import { TextField } from "../../components/text-field";
import { Button } from "../../components/button";

import { useApi } from "../../hooks/useApi";
import { MailOppened } from "../../components/icons/dark/mail-oppened";
import LogoCreativeRoxo from "../../assets/img/logos/braip-creative-horizontal-roxo.svg";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isDisabled] = useState(false);

  const { sendRecoveryPassword, loading } = useApi();

  function validEmail() {
    const emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (!email) {
      setErrorEmail("O campo é obrigatório.");
    } else if (!emailRegex.test(email)) {
      setErrorEmail("O e-mail informado é inválido.");
    } else {
      setErrorEmail("");
    }
  }

  const handleSendRecoveryPassword = async (e) => {
    e.preventDefault();

    validEmail();

    if (errorEmail) return;

    sendRecoveryPassword({ email: email.trim() })
      .then((res) => {
        setSubmitted(true);
        console.log("foiiii");
        // navigate("/app");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="flex flex-col min-h-full bg-top bg-no-repeat bg-cover bg-primary-600 bg-braip-login">
      <main className="flex flex-col items-center justify-center flex-1 min-w-fit">
        {!submitted ? (
          <form
            className="flex flex-col max-w-md gap-12 p-10 mx-4 bg-white rounded-sm mt-xxl"
            onSubmit={handleSendRecoveryPassword}
          >
            <div className="flex flex-col gap-14">
              <div className="flex items-center justify-between">
                <img
                  className="w-36"
                  src="/logos/braip-creative-horizontal-roxo.svg"
                  alt="Logo do Braip Canvas"
                />
              </div>

              <div class="flex flex-col gap-nano">
                <h1 class="text-dark-100 text-h7 font-poppins font-semibold">
                  Esqueceu a senha?
                </h1>
                <p class="text-light-700 text-p4">
                  Digite o e-mail vinculado à sua conta no espaço abaixo e em
                  seguida enviaremos um link para redefinir a sua senha.
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-xs">
              <div class="flex flex-col gap-xxs">
                <div class="flex flex-col gap-xxxs">
                  <TextField
                    label="E-mail"
                    id="email"
                    type="text"
                    value={email}
                    errorMessage={errorEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorEmail("");
                    }}
                  />
                </div>
              </div>
              <div class="flex flex-col items-center gap-xxs">
                <Button
                  text="Enviar"
                  type="submit"
                  className="text-center"
                  size="large"
                  semibold
                  full
                  disabled={isDisabled}
                  loading={loading["forgot-password"]}
                />
                <Link
                  to="/auth/sign-in"
                  class="text-p5 !font-medium !no-underline !text-light-600"
                >
                  Cancelar
                </Link>
              </div>
            </div>
          </form>
        ) : (
          <div class="mx-4 mt-xxl flex max-w-md flex-col gap-12 rounded-sm bg-white p-10">
            <div class="flex flex-col gap-14">
              <div class="flex items-center justify-between">
                <img
                  className="w-36"
                  src="/logos/braip-creative-horizontal-roxo.svg"
                  alt="Logo do Braip Canvas"
                />
              </div>
              <div class="flex justify-center">
                <div class="p-6 bg-linear-purple">
                  <MailOppened class="fill-primary-600" size={50} />
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-xs">
              <div class="flex flex-col gap-nano">
                <h1 class="text-dark-100 text-h7 font-poppins font-semibold text-center">
                  E-mail enviado com sucesso!
                </h1>
                <p class="text-light-700 text-p4 text-center">
                  O link para redefinição de senha foi enviado para o seu
                  e-mail.
                </p>
              </div>
              <Button
                text="Concluir"
                class="!justify-center"
                size="large"
                semibold
                full
                to="/auth/sign-in"
              />
            </div>
          </div>
        )}
      </main>
      <footer className="flex justify-center w-full">
        <p className="mx-4 my-xs w-36 sm:w-full text-center text-p6 !text-light-0/40">
          © Braip 2024. Todos os direitos reservados
        </p>
      </footer>
    </div>
  );
};
