import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  FilePlus,
  Plus,
  Settings,
  Copy,
  Pen,
  ArrowRight,
  Trash2,
  Minus,
  Shield,
  Check,
  AlertTriangle,
} from "lucide-react";

export const Button = ({
  type = "link",
  icon,
  text = "Texto do botão",
  to,
  href,
  target,
  useObject = false,
  toName,
  toId,
  variant = "primary",
  size = "small",
  mode = "normal",
  loading = false,
  disabled = false,
  full = false,
  semibold = false,
  onClick,
}) => {
  const ComponentType = useMemo(() => {
    return to ? Link : href ? "a" : "button";
  }, [to, href]);

  const componentProps = useMemo(() => {
    if (to) {
      return {
        to: useObject ? { pathname: toName, state: { id: toId } } : to,
        disabled,
      };
    } else if (href) {
      return { href, target };
    } else {
      return { type, disabled };
    }
  }, [to, href, useObject, toName, toId, type, disabled, target]);

  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
    } else if (onClick) {
      onClick(event);
    }
  };

  const renderIcon = () => {
    switch (icon) {
      case "trash":
        return <Trash2 size={20} />;
      case "pen":
        return <Pen size={20} />;
      case "plus":
        return <Plus size={20} />;
      case "minus":
        return <Minus size={20} />;
      case "new-page":
        return <FilePlus size={20} />;
      case "shield":
        return <Shield size={20} />;
      case "settings":
        return <Settings size={20} />;
      case "copy":
        return <Copy size={18} />;
      case "check":
        return <Check size={20} />;
      case "alert-triangle":
        return <AlertTriangle size={20} />;
      case "arrow-right":
        return <ArrowRight size={20} />;
      default:
        return null;
    }
  };

  return (
    <ComponentType
      {...componentProps}
      onClick={handleClick}
      className={`flex w-fit gap-2 items-center justify-center font-poppins font-semibold rounded-md !text-center transition disabled:cursor-not-allowed focus:outline-transparent hover:!no-underline ${
        full ? "!w-full" : ""
      } ${getButtonClasses(variant, mode, size, loading, semibold)}`}
    >
      {loading ? (
        <span className="w-5 h-5 border-2 border-transparent rounded-full border-t-slate-300 border-l-slate-300 animate-spin"></span>
      ) : (
        <>
          {icon && icon !== "arrow-right" && (
            <span className="icons">{renderIcon()}</span>
          )}
          <span className="whitespace-nowrap">{text}</span>
          {icon === "arrow-right" && (
            <span className="icons">{renderIcon()}</span>
          )}
        </>
      )}
    </ComponentType>
  );
};

function getButtonClasses(variant, mode, size, loading, semibold) {
  const baseClasses = {
    primary: {
      normal:
        "bg-primary-600 !text-light-0 disabled:!text-light-0/40 hover:bg-primary-700/90 active:bg-primary-700/80 disabled:bg-light-300/30",
      destructive:
        "bg-red-600 !text-light-0 disabled:!text-light-0/40 hover:bg-red-700/90 active:bg-red-700/80 disabled:bg-light-300/30",
      success:
        "bg-green-600 !text-light-0 disabled:!text-light-0/40 hover:bg-green-700/90 active:bg-green-700/80 disabled:bg-light-300/30",
    },
    secundary: {
      normal:
        "bg-primary-400/10 !text-primary-600 disabled:!text-light-400/40 hover:bg-primary-500/10 active:bg-primary-600/10 disabled:bg-light-200/10",
      destructive:
        "bg-red-400/10 !text-red-600 disabled:!text-light-400/40 hover:bg-red-500/10 active:bg-red-600/10 disabled:bg-light-200/10",
      success:
        "bg-green-400/10 !text-green-600 disabled:!text-light-400/40 hover:bg-green-500/10 active:bg-green-600/10 disabled:bg-light-200/10",
    },
    outline: {
      normal:
        "border border-light-300/20 bg-transparent !text-primary-600 shadow-none hover:border-primary-500/10 hover:bg-primary-400/10 active:border-primary-600/10 active:bg-primary-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
      destructive:
        "border border-light-300/20 bg-transparent !text-red-600 shadow-none hover:border-red-500/10 hover:bg-red-400/10 active:border-red-600/10 active:bg-red-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
      success:
        "border border-light-300/20 bg-transparent !text-green-600 shadow-none hover:border-green-500/10 hover:bg-green-400/10 active:border-green-600/10 active:bg-green-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
    },
    only: {
      normal:
        "border-none bg-transparent !font-medium !text-light-300 shadow-none hover:!text-primary-600 hover:bg-primary-400/10 active:bg-primary-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
      destructive:
        "border-none bg-transparent !font-medium !text-light-300 shadow-none hover:!text-red-600 hover:bg-red-400/10 active:bg-red-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
      success:
        "border-none bg-transparent !font-medium !text-light-300 shadow-none hover:!text-green-600 hover:bg-green-400/10 active:bg-green-500/10 disabled:border-light-300/20 disabled:bg-transparent disabled:!text-light-400/40",
    },
  };

  const sizeClasses = {
    "extra-large": "h-[72px] px-8 !text-p3",
    large: "h-14 px-6 !text-p4",
    medium: "h-12 px-6 !text-p5",
    small: "h-10 px-6 !text-p6",
    "extra-small": "h-8 px-4 !text-p7",
  };

  return `${baseClasses[variant][mode]} ${sizeClasses[size]} ${
    semibold ? "font-semibold" : ""
  } ${loading ? "cursor-wait" : ""}`;
}
