import { nanoid } from "nanoid";
import localforage from "localforage";
import { dataURLtoBlob } from "./blob";
import { axiosPrivate } from "./api/axios";
import axios from "axios";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

const isSignedIn = () => {
  return window.puter?.auth?.isSignedIn();
};

async function writeFile(fileName, data) {
  if (isSignedIn()) {
    await window.puter.fs.write(fileName, data, { createMissingParents: true });
  } else {
    await localforage.setItem(fileName, data);
  }
}

async function readFile(fileName) {
  if (isSignedIn()) {
    return await window.puter.fs.read(fileName);
  }
  return await localforage.getItem(fileName);
}

async function deleteFile(fileName) {
  if (isSignedIn()) {
    return await window.puter.fs.delete(fileName);
  }
  return await localforage.removeItem(fileName);
}

async function readKv(key) {
  if (isSignedIn()) {
    return await window.puter.kv.get(key);
  } else {
    return await localforage.getItem(key);
  }
}

async function writeKv(key, value) {
  if (isSignedIn()) {
    return await window.puter.kv.set(key, value);
  } else {
    return await localforage.setItem(key, value);
  }
}

export async function backupFromLocalToCloud() {
  const localDesigns = (await localforage.getItem("designs-list")) || [];
  for (const design of localDesigns) {
    const storeJSON = await localforage.getItem(`designs/${design.id}.json`);
    const preview = await localforage.getItem(`designs/${design.id}.jpg`);
    await writeFile(`designs/${design.id}.json`, storeJSON);
    await writeFile(`designs/${design.id}.jpg`, preview);
  }
  const cloudDesigns = (await window.puter.kv.get("designs-list")) || [];
  cloudDesigns.push(...localDesigns);
  await window.puter.kv.set("designs-list", cloudDesigns);
  await localforage.removeItem("designs-list");
  for (const design of localDesigns) {
    await localforage.removeItem(`designs/${design.id}.json`);
    await localforage.removeItem(`designs/${design.id}.jpg`);
  }
  return cloudDesigns.length;
}

export async function listDesigns({ page = 1, search = "" } = {}) {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];

  let url = `/projects?page=${page ?? 1}`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }

  const response = await axiosPrivate.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${auth?.token_type} ${auth?.access_token}`,
    },
  });

  // console.log("response", response);
  // console.log(await readKv("designs-list"));
  return response.data.data;
}

export async function deleteDesign({ id }) {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];
  const response = await axiosPrivate(`/projects/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${auth?.token_type} ${auth?.access_token}`,
    },
  });
  if (response.status === 204) {
    await deleteFile(`design/${id}.jpg`);
  }
}

export async function loadById({ id }) {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];
  try {
    const response = await axiosPrivate(`/projects/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${auth?.token_type} ${auth?.access_token}`,
      },
    });
    const { title, data, template_id } = response.data.data;

    const storeJSON = (typeof data === "string") ? JSON.parse(data) : data;
    console.log("STORE JSON LOAD BY ID", storeJSON);

    if (!template_id) {
      localStorage.removeItem("braip-creative-template-saved");
      return { storeJSON, name: title };
    }

    localStorage.setItem("braip-creative-template-saved", template_id);
    return {
      storeJSON,
      name: title,
      template_id,
    };
  } catch (error) {
    if (error.response.status) {
      throw Error(error);
    }
    console.log(error.response);
  }
}

export async function loadByTemplateId({ template_id }) {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];
  try {
    const response = await axiosPrivate(`/templates/${template_id}?fields=title,data`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${auth?.token_type} ${auth?.access_token}`,
      },
    });
    const dataTemplate = response.data.data;

    // let storeJSON = await readFile(`designs/${id}.json`);
    // const list = await listDesigns();
    // const design = list.find((design) => design.id === id);
    // // if it is blob, convert to JSON
    // if (storeJSON instanceof Blob) {
    //   storeJSON = JSON.parse(await storeJSON.text());
    // } else if (typeof storeJSON === "string") {
    //   storeJSON = JSON.parse(storeJSON);
    // }

    const storeJSON = (typeof dataTemplate.data === "string") ? JSON.parse(dataTemplate.data) : dataTemplate.data;

    return { storeJSON: storeJSON, name: dataTemplate.title };
  } catch (error) {
    console.log("ERROR TEMPLATE ID: ", error);
  }
}
// export async function saveToLocal({ storeJSON, preview, name, id }) {
//   let designId = id;

//   const auth = await localforage.getItem("auth");
//   if (!auth) return;

//   await writeFile(`thumb/${designId}`, preview);
//   await writeKv("status-thumb", "not-saved");

//   const image = await getAssetSrc({ id: `thumb/${designId}` });
//   await writeKv(
//     `design/${designId}`,
//     JSON.stringify({
//       id: designId,
//       title: name || "Sem nome",
//       data: JSON.stringify(storeJSON),
//       thumbnail: image,
//     })
//   );
//   await writeKv("status-design", "not-saved");

//   localStorage.setItem("braip-creative-last-design-id", designId);

//   return { id: designId, status: "local-saved" };
// }
export async function saveDesign({
  storeJSON,
  preview,
  name,
  id,
  template_id,
  createNew,
  mode,
}) {
  let designId = id;
  let tempCreateNew = createNew || false;
  const auth = await localforage.getItem("auth");
  if (!auth) return;

  const newThumb = new File([preview], `thumbnail-${id}.jpg`);
  const formData = new FormData();
  formData.append("file", newThumb);
  formData.append("source", "design-thumb");
  let responseFile;
  try {
    responseFile = await axiosPrivate("/files/upload", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${auth?.token_type} ${auth?.access_token}`,
      },
      data: formData,
    });
  } catch (error) {
    Toast.fire("Error", "Ocorreu um erro ao Enviar arquivo de Thumbnail!", "error");
    console.log("ERRO UPLOAD THUMBNAIL: ", error);
  }

  const data = {
    id: mode === "template" ? template_id : designId,
    title: name || "Sem nome",
    data: JSON.stringify(storeJSON),
    thumbnail: responseFile.data.url,
  };

  if (mode === "template") {
    data.size = window.store
      ? `${window.store.width}x${window.store.height}`
      : "";
    data.project_id = designId;
    console.log(!template_id);
    tempCreateNew = !template_id;
  }

  const path = tempCreateNew ? "/projects" : `/projects/${designId}`;
  const pathTemplate = tempCreateNew
    ? "/templates"
    : `/templates/${template_id}`;

    try {
      const response = await axiosPrivate(
        mode === "template" ? pathTemplate : path,
        {
          method: tempCreateNew ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${auth?.token_type} ${auth?.access_token}`,
          },
          data,
        }
      );
      const responseData = response.data.data.response;
      console.log("responseData", responseData);
  
      let list = await listDesigns();
      const existing = list.data.find((design) => design.id === responseData.id);
      if (existing) {
        existing.name = name;
      } else {
        list.data.push({ id: responseData.id, name });
      }
  
      await writeKv("designs-list", list);
      Toast.fire("Tudo Certo!", "Projeto salvo com sucesso!", "success");
  
      return {
        id: responseData.id,
        status: "saved",
        type: mode === "template" ? "template" : "project",
      };
    } catch (error) {
      Toast.fire("Tivemos um problema", "Ocorreu um erro ao salvar o projeto!s", "error");
      console.log("ERROR SAVE PROJECT: ", error);
    }
}

export const getPreview = async ({ id }) => {
  const preview = await readFile(`designs/${id}.jpg`);
  if (!preview) return "https://placeholder.com/200x200";
  return URL.createObjectURL(preview);
};

const batchCall = (asyncFunction) => {
  let cachedPromise = null;
  return async (...args) => {
    if (!cachedPromise) {
      cachedPromise = asyncFunction(...args).catch((error) => {
        // Reset cachedPromise on error to allow retry
        cachedPromise = null;
        throw error;
      });
    }
    return cachedPromise;
  };
};

let subDomainCache = null;
const getPublicSubDomain = batchCall(async () => {
  if (subDomainCache) {
    return subDomainCache;
  }
  // fist we need to validate domain
  const sites = await window.puter.hosting.list();
  const user = await window.puter.auth.getUser();
  const prefix = user.username + "-pltn-pld";
  let subdomain = prefix;
  const existingDomain = sites.find(
    (site) => site.subdomain.indexOf(prefix) >= 0
  );

  if (existingDomain) {
    subDomainCache = existingDomain.subdomain;
    return existingDomain.subdomain;
  }
  let attempt = 1;
  while (attempt < 10) {
    const postfix = attempt > 1 ? `-${attempt}` : "";
    subdomain = `${prefix}${postfix}`;
    try {
      await window.puter.fs.mkdir("uploads", { createMissingParents: true });
      await window.puter.hosting.create(subdomain, "uploads");
      break;
    } catch (error) {
      attempt++;
      continue;
    }
  }
  if (attempt >= 10) {
    throw new Error("Failed to create subdomain");
  }
  subDomainCache = subdomain;
  return subdomain;
});

export const listAssets = async () => {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];

  const response = await axiosPrivate("/files", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${auth?.token_type} ${auth?.access_token}`,
    },
  });
  for (const file of response.data.data) {
    // TODO: Remover quando o s3 funcionar.
    // file.url = placeholder;
    // TODO END
    file.src = file.url;
    // file.src = placeholder;
    file.preview = file.thumbnail || file.url;
    // file.preview = placeholder;
    file.type = file.type ? file.type : "image";
  }
  return response.data.data;
};

export const getAssetSrc = async ({ id }) => {
  const file = await readFile(id);
  return URL.createObjectURL(file);
};
export const getAssetPreviewSrc = async ({ id }) => {
  const file = await readFile(`uploads/${id}-preview`);
  console.log("file", file);
  return URL.createObjectURL(file);
};
export const uploadAsset = async ({ file, preview, type }) => {
  const list = await listAssets();
  const id = nanoid(10);
  await writeFile(`uploads/${id}`, file);
  await writeFile(`uploads/${id}-preview`, preview);
  list.push({ id, type });
  await writeKv("assets-list", list);

  const src = await getAssetSrc({ id });
  const previewSrc = await getAssetPreviewSrc({ id });
  return { id, src, preview: previewSrc };
};

export const deleteAsset = async ({ id }) => {
  const list = await listAssets();
  const newList = list.filter((asset) => asset.id !== id);
  await writeKv("assets-list", newList);
};

export async function listTemplates({
  sameSize,
  width,
  height,
  perPage,
  page,
  query,
}) {
  const auth = await localforage.getItem("auth");
  if (!auth) return [];
  const response = await axiosPrivate.get("/templates", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${auth?.token_type} ${auth?.access_token}`,
    },
    params: {
      size: sameSize ? width + "x" + height : "",
      per_page: perPage,
      page,
      query,
      fields: 'id,title,thumbnail,size',
    },
  });

  const templates = response.data.data.items.map((template) => ({
    id: template.id,
    name: template.title,
    thumbnail: template.thumbnail,
  }));

  return {
    hits: response.data.data.hits,
    items: templates,
    totalPages: response.data.data.totalPages,
  };
}
