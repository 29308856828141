import { Button } from "../components/button";
import LogoWhite from "../assets/img/logos/braip-creative-horizontal-branco.svg";
import "../assets/styles/Error404.css";

export const Error404 = () => {
  return (
    <div className="w-screen h-screen p-8 overflow-x-hidden bg-center bg-no-repeat bg-cover bg-404">
      <main className="flex items-center justify-between h-full mx-auto max-w-screen-2xl">
        <section id="content" className="flex flex-col items-start gap-8">
          <img
            src={LogoWhite}
            alt="Logo do braip canvas"
            className="h-20 p-2"
          />
          <div className="max-w-[600px]">
            <h1 className="mb-3 font-semibold whitespace-normal font-sora text-light-0 text-h4 md:whitespace-nowrap lg:whitespace-normal">
              Ops! Página não encontrada!
            </h1>
            <p className="text-dark-800 text-p4">
              A página que você procura mudou de endereço ou não está mais
              disponível. Clique no botão abaixo para ir para a página inicial.
            </p>
          </div>
          <Button
            text="Ir para a página inicial"
            variant="only"
            size="large"
            to="/"
            className="!font-semibold"
          />
        </section>
        <div id="error" size="1" className="box max-w-[600px] md:mt-16 hidden md:flex">
          <svg
            width="696"
            height="674"
            viewBox="0 0 696 674"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >

            <g class="slide-in">

              <path
                d="M343.459 192.662L318.968 199.193V68.5744L343.459 62.0435V192.662Z"
                fill="#4C21BB"
              ></path>
              <path
                d="M416.932 212.255L392.441 218.786L318.968 199.193L343.459 192.662L416.932 212.255Z"
                fill="#4518B8"
              ></path>
              <path
                d="M416.932 238.379L392.441 244.91V62.0435L416.932 55.5125V238.379Z"
                fill="#4D22BB"
              ></path>
              <path
                d="M318.968 29.3888L294.477 35.9197L392.441 62.0435L416.932 55.5125L318.968 29.3888Z"
                fill="#4518B8"
              ></path>

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M294.476 218.786V35.92L392.44 62.0437V244.91L294.476 218.786ZM318.967 199.193V68.5747L367.949 81.6366V212.255L318.967 199.193Z"
                fill="url(#paint0_linear_1_28)"
              ></path>

              <path
                d="M294.476 35.92L294.687 35.1312L293.66 34.8574V35.92H294.476ZM294.476 218.786H293.66V219.413L294.266 219.575L294.476 218.786ZM392.44 62.0437H393.257V61.4165L392.651 61.2549L392.44 62.0437ZM392.44 244.91L392.23 245.699L393.257 245.973V244.91H392.44ZM318.967 68.5747L319.178 67.7859L318.151 67.5121V68.5747H318.967ZM318.967 199.193H318.151V199.821L318.757 199.982L318.967 199.193ZM367.949 81.6366H368.766V81.0094L368.16 80.8477L367.949 81.6366ZM367.949 212.255L367.739 213.044L368.766 213.318V212.255H367.949ZM293.66 35.92V218.786H295.293V35.92H293.66ZM392.651 61.2549L294.687 35.1312L294.266 36.7088L392.23 62.8325L392.651 61.2549ZM393.257 244.91V62.0437H391.624V244.91H393.257ZM294.266 219.575L392.23 245.699L392.651 244.121L294.687 217.997L294.266 219.575ZM318.151 68.5747V199.193H319.784V68.5747H318.151ZM368.16 80.8477L319.178 67.7859L318.757 69.3635L367.739 82.4254L368.16 80.8477ZM368.766 212.255V81.6366H367.133V212.255H368.766ZM318.757 199.982L367.739 213.044L368.16 211.467L319.178 198.405L318.757 199.982Z"
                fill="#AD8EFF"
              ></path>
              <path
                d="M214.473 35.9202V62.0439L189.982 94.6986L165.491 101.23L214.473 35.9202Z"
                fill="#4C21BB"
              ></path>
              <path
                d="M238.964 107.76L214.473 114.291L165.491 101.23L189.982 94.6986L238.964 107.76Z"
                fill="#4518B8"
              ></path>
              <path
                d="M263.455 192.663L238.964 199.194V146.946L263.455 153.477V192.663Z"
                fill="#4D22BB"
              ></path>
              <path
                d="M287.946 146.946L263.455 153.477V127.353L287.946 120.822V146.946Z"
                fill="#4C21BB"
              ></path>
              <path
                d="M263.455 114.291L238.964 120.822L263.455 127.353L287.946 120.822L263.455 114.291Z"
                fill="#4518B8"
              ></path>
              <path
                d="M263.455 114.291L238.964 120.822V16.3274L263.455 9.79642V114.291Z"
                fill="#4D22BB"
              ></path>
              <path
                d="M226.718 0L202.227 6.53094L238.964 16.3273L263.455 9.79641L226.718 0Z"
                fill="#4518B8"
              ></path>

              <mask id="path-14-inside-1_1_28" fill="white">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M238.964 199.193L214.473 192.662V140.415L141 120.822V94.6983L202.228 6.53064L238.964 16.327V120.822L263.455 127.353V153.477L238.964 146.946V199.193ZM165.491 101.229L214.473 114.291V35.9199L165.491 101.229Z"
                ></path>
              </mask>

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M238.964 199.193L214.473 192.662V140.415L141 120.822V94.6983L202.228 6.53064L238.964 16.327V120.822L263.455 127.353V153.477L238.964 146.946V199.193ZM165.491 101.229L214.473 114.291V35.9199L165.491 101.229Z"
                fill="url(#paint1_linear_1_28)"
              ></path>

              <path
                d="M214.473 192.662H212.84V193.917L214.052 194.24L214.473 192.662ZM238.964 199.193L238.543 200.771L240.597 201.318V199.193H238.964ZM214.473 140.415H216.106V139.16L214.894 138.837L214.473 140.415ZM141 120.822H139.367V122.076L140.579 122.4L141 120.822ZM141 94.6983L139.659 93.767L139.367 94.187V94.6983H141ZM202.228 6.53064L202.648 4.95303L201.54 4.65762L200.886 5.59933L202.228 6.53064ZM238.964 16.327H240.597V15.0727L239.385 14.7494L238.964 16.327ZM238.964 120.822H237.331V122.076L238.543 122.4L238.964 120.822ZM263.455 127.353H265.088V126.099L263.876 125.775L263.455 127.353ZM263.455 153.477L263.034 155.054L265.088 155.602V153.477H263.455ZM238.964 146.946L239.385 145.368L237.331 144.821V146.946H238.964ZM214.473 114.291L214.052 115.869L216.106 116.416V114.291H214.473ZM165.491 101.229L164.185 100.25L162.734 102.184L165.07 102.807L165.491 101.229ZM214.473 35.9199H216.106V31.0217L213.167 34.9402L214.473 35.9199ZM214.052 194.24L238.543 200.771L239.385 197.616L214.894 191.085L214.052 194.24ZM212.84 140.415V192.662H216.106V140.415H212.84ZM140.579 122.4L214.052 141.992L214.894 138.837L141.421 119.244L140.579 122.4ZM139.367 94.6983V120.822H142.633V94.6983H139.367ZM200.886 5.59933L139.659 93.767L142.341 95.6296L203.569 7.46194L200.886 5.59933ZM239.385 14.7494L202.648 4.95303L201.807 8.10824L238.543 17.9047L239.385 14.7494ZM240.597 120.822V16.327H237.331V120.822H240.597ZM263.876 125.775L239.385 119.244L238.543 122.4L263.034 128.931L263.876 125.775ZM265.088 153.477V127.353H261.822V153.477H265.088ZM238.543 148.523L263.034 155.054L263.876 151.899L239.385 145.368L238.543 148.523ZM240.597 199.193V146.946H237.331V199.193H240.597ZM214.894 112.713L165.912 99.6516L165.07 102.807L214.052 115.869L214.894 112.713ZM212.84 35.9199V114.291H216.106V35.9199H212.84ZM166.797 102.209L215.779 36.8995L213.167 34.9402L164.185 100.25L166.797 102.209Z"
                fill="#AD8EFF"
                mask="url(#path-14-inside-1_1_28)"
              ></path>
              <path
                d="M496.936 120.822V146.946L472.445 179.601L447.954 186.132L496.936 120.822Z"
                fill="#4C21BB"
              ></path>
              <path
                d="M521.427 192.663L496.936 199.193L447.954 186.132L472.445 179.601L521.427 192.663Z"
                fill="#4518B8"
              ></path>
              <path
                d="M545.918 277.565L521.427 284.096V231.848L545.918 238.379V277.565Z"
                fill="#4D22BB"
              ></path>
              <path
                d="M570.409 231.848L545.918 238.379V212.255L570.409 205.724V231.848Z"
                fill="#4C21BB"
              ></path>
              <path
                d="M545.918 199.193L521.427 205.724L545.918 212.255L570.409 205.724L545.918 199.193Z"
                fill="#4518B8"
              ></path>
              <path
                d="M545.918 199.193L521.427 205.724V101.229L545.918 94.6985V199.193Z"
                fill="#4D22BB"
              ></path>
              <path
                d="M509.182 84.9021L484.691 91.433L521.427 101.229L545.918 94.6985L509.182 84.9021Z"
                fill="#4518B8"
              ></path>

              <mask id="path-23-inside-2_1_28" fill="white">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M521.428 284.095L496.937 277.564V225.317L423.464 205.724V179.6L484.691 91.4325L521.428 101.229V205.724L545.919 212.255V238.379L521.428 231.848V284.095ZM447.955 186.131L496.937 199.193V120.822L447.955 186.131Z"
                ></path>
              </mask>

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M521.428 284.095L496.937 277.564V225.317L423.464 205.724V179.6L484.691 91.4325L521.428 101.229V205.724L545.919 212.255V238.379L521.428 231.848V284.095ZM447.955 186.131L496.937 199.193V120.822L447.955 186.131Z"
                fill="url(#paint2_linear_1_28)"
              ></path>

              <path
                d="M496.937 277.564H495.304V278.819L496.516 279.142L496.937 277.564ZM521.428 284.095L521.007 285.673L523.06 286.22V284.095H521.428ZM496.937 225.317H498.569V224.062L497.357 223.739L496.937 225.317ZM423.464 205.724H421.831V206.978L423.043 207.302L423.464 205.724ZM423.464 179.6L422.123 178.669L421.831 179.089V179.6H423.464ZM484.691 91.4325L485.112 89.8549L484.004 89.5595L483.35 90.5012L484.691 91.4325ZM521.428 101.229H523.06V99.9745L521.848 99.6513L521.428 101.229ZM521.428 205.724H519.795V206.978L521.007 207.302L521.428 205.724ZM545.919 212.255H547.551V211L546.339 210.677L545.919 212.255ZM545.919 238.379L545.498 239.956L547.551 240.504V238.379H545.919ZM521.428 231.848L521.848 230.27L519.795 229.723V231.848H521.428ZM496.937 199.193L496.516 200.771L498.569 201.318V199.193H496.937ZM447.955 186.131L446.648 185.151L445.198 187.086L447.534 187.709L447.955 186.131ZM496.937 120.822H498.569V115.924L495.63 119.842L496.937 120.822ZM496.516 279.142L521.007 285.673L521.848 282.518L497.357 275.987L496.516 279.142ZM495.304 225.317V277.564H498.569V225.317H495.304ZM423.043 207.302L496.516 226.894L497.357 223.739L423.884 204.146L423.043 207.302ZM421.831 179.6V205.724H425.096V179.6H421.831ZM483.35 90.5012L422.123 178.669L424.805 180.531L486.032 92.3638L483.35 90.5012ZM521.848 99.6513L485.112 89.8549L484.27 93.0101L521.007 102.807L521.848 99.6513ZM523.06 205.724V101.229H519.795V205.724H523.06ZM546.339 210.677L521.848 204.146L521.007 207.302L545.498 213.832L546.339 210.677ZM547.551 238.379V212.255H544.286V238.379H547.551ZM521.007 233.425L545.498 239.956L546.339 236.801L521.848 230.27L521.007 233.425ZM523.06 284.095V231.848H519.795V284.095H523.06ZM497.357 197.615L448.375 184.554L447.534 187.709L496.516 200.771L497.357 197.615ZM495.304 120.822V199.193H498.569V120.822H495.304ZM449.261 187.111L498.243 121.801L495.63 119.842L446.648 185.151L449.261 187.111Z"
                fill="#AD8EFF"
                mask="url(#path-23-inside-2_1_28)"
              ></path>
            </g>

            <path
              d="M343.416 550.618L221.606 518.135L343.416 485.652L465.227 518.135L343.416 550.618Z"
              fill="#6D36FB"
            ></path>
            <path
              opacity="0.4"
              d="M343.078 546.557L236.493 518.135L343.078 489.712L449.662 518.135L343.078 546.557Z"
              fill="#290091"
            ></path>
            <path
              d="M221.606 518.135V539.79L343.416 572.273V550.618L221.606 518.135Z"
              fill="#4C22BB"
            ></path>
            <path
              d="M465.227 518.135V539.79L343.416 572.273V550.618L465.227 518.135Z"
              fill="#4018A7"
            ></path>
            <g filter="url(#filter0_f_1_28)">
              <path
                d="M192.5 540.5C204.833 537.167 229.6 530.5 230 530.5H455L492 540.5L342.5 581L192.5 540.5Z"
                fill="#6D36FB"
                fill-opacity="0.3"
              ></path>
            </g>

            <path
              d="M279.5 555L113.5 600L193 621.5L1 672.5"
              stroke="url(#paint3_linear_1_28)"
            ></path>

            <path
              d="M417 508.5L583 463.5L503.5 442L695.5 391"
              stroke="url(#paint4_linear_1_28)"
            ></path>

            <path
              d="M299.5 501.5L133.5 456.5L213 435L21 384"
              stroke="url(#paint5_linear_1_28)"
            ></path>

            <path
              d="M399 556L565 601L485.5 622.5L677.5 673.5"
              stroke="url(#paint6_linear_1_28)"
            ></path>

            <g filter="url(#filter1_i_1_28)">
              <path
                d="M343.416 539.79L262.209 518.135L343.416 496.48L424.623 518.135L343.416 539.79Z"
                fill="url(#paint7_linear_1_29)"
                fill-opacity="1"
              ></path>
            </g>

            <path
              d="M343.416 539.79L424.623 518.135L505.83 111.434H181.002L262.209 518.135L343.416 539.79Z"
              fill="url(#paint7_linear_1_28)"
              fill-opacity="0.6"
            ></path>

            <defs>
              <filter
                id="filter0_f_1_28"
                x="172.5"
                y="510.5"
                width="339.5"
                height="90.5"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feGaussianBlur
                  stdDeviation="10"
                  result="effect1_foregroundBlur_1_28"
                ></feGaussianBlur>
              </filter>

              <filter
                id="filter1_i_1_28"
                x="262.209"
                y="496.48"
                width="162.414"
                height="46.5759"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                ></feBlend>
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                ></feColorMatrix>
                <feOffset dy="3.26547"></feOffset>
                <feGaussianBlur stdDeviation="3.26547"></feGaussianBlur>
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                ></feComposite>
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                ></feColorMatrix>
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_1_28"
                ></feBlend>
              </filter>

              <linearGradient
                id="paint1_linear_1_28"
                x1="202.228"
                y1="6.53064"
                x2="202.228"
                y2="199.193"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white"></stop>
                <stop offset="1" stop-color="#CBB7FF"></stop>
              </linearGradient>

              <linearGradient
                id="paint0_linear_1_28"
                x1="343.458"
                y1="35.92"
                x2="343.458"
                y2="244.91"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white"></stop>
                <stop offset="1" stop-color="#CBB7FF"></stop>
              </linearGradient>

              <linearGradient
                id="paint2_linear_1_28"
                x1="484.691"
                y1="91.4325"
                x2="484.691"
                y2="284.095"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white"></stop>
                <stop offset="1" stop-color="#CBB7FF"></stop>
              </linearGradient>

              <linearGradient
                id="paint4_linear_1_28"
                x1="556.25"
                y1="508.5"
                x2="556.25"
                y2="391"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#6D36FB">
                  <animate
                    attributeName="stop-color"
                    values="#6D36FB22; #6D36FB; #6D36FB22"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>

                <stop offset="1" stop-color="#0F1116" stop-opacity="0">
                  <animate
                    attributeName="stop-color"
                    values="#fff4"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>

              <linearGradient
                id="paint5_linear_1_28"
                x1="160.25"
                y1="501.5"
                x2="160.25"
                y2="384"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#6D36FB">
                  <animate
                    attributeName="stop-color"
                    values="#6D36FB22; #6D36FB; #6D36FB22"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>

                <stop offset="1" stop-color="#0F1116" stop-opacity="0">
                  <animate
                    attributeName="stop-color"
                    values="#fff4"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>

              <linearGradient
                id="paint6_linear_1_28"
                x1="538.25"
                y1="556"
                x2="538.25"
                y2="673.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#6D36FB">
                  <animate
                    attributeName="stop-color"
                    values="#6D36FB22; #6D36FB; #6D36FB22"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>

                <stop offset="1" stop-color="#0F1116" stop-opacity="0">
                  <animate
                    attributeName="stop-color"
                    values="#fff4"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>

              <linearGradient
                id="paint3_linear_1_28"
                x1="140.25"
                y1="555"
                x2="140.25"
                y2="672.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#6D36FB">
                  <animate
                    attributeName="stop-color"
                    values="#6D36FB22; #6D36FB; #6D36FB22"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>

                <stop offset="1" stop-color="#0F1116" stop-opacity="0">
                  <animate
                    attributeName="stop-color"
                    values="#fff4"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>

              <linearGradient
                id="paint7_linear_1_28"
                x1="343.416"
                y1="160.824"
                x2="343.416"
                y2="539.79"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#fff1" stop-opacity="0.1"></stop>

                <stop offset="1" stop-color="white" stop-opacity="1">
                  <animate
                    attributeName="stop-color"
                    values="#fff1; #fff5; #fff1"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>

              <linearGradient
                id="paint7_linear_1_29"
                x1="343.416"
                y1="160.824"
                x2="343.416"
                y2="539.79"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="1" stop-color="white" stop-opacity="1">
                  <animate
                    attributeName="stop-color"
                    values="#fff7; #fff; #fff7"
                    dur="4s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </main>
    </div>
  );
};
