import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import Swal from "sweetalert2";
import { Button } from "./button";
import { useProject } from "../project";

export const ProjectsList = () => {

  const project = useProject();
  const { getProjects, createFolder, moveProjectToFolder, getFolders } = useApi();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 20,
    current_page: 1,
    last_page: 1,
  });
  const navigate = useNavigate();

  const fetchData = async (page = 1, folderId = null, searchTerm = null) => {
    try {
      setLoading(true);
      const filters = folderId ? `&folder_id=${folderId}` : null;
      const search = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : null;
      console.log("filters", filters);
      console.log("search", search);
      const projectsData = await getProjects(page, filters, search);
      setProjects(projectsData.data);
      setFilteredProjects(projectsData.data);
      setPagination({
        total: projectsData.total,
        per_page: projectsData.per_page,
        current_page: projectsData.current_page,
        last_page: projectsData.last_page,
      });

      const foldersData = await getFolders();
      setFolders(foldersData.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pagination.last_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    // Reseta memória do builder
    project.clear();
    fetchData(currentPage);
  }, [currentPage]);

  const handleAddProject = () => {
    navigate("/app");
  };

  const handleProjectClick = (id) => {
    localStorage.setItem("braip-creative-last-design-id", id);
    navigate("/app");
  };

  const handleCreateFolder = async () => {
    const { value: folderName } = await Swal.fire({
      title: "Criar nova pasta",
      input: "text",
      inputLabel: "Nome da pasta",
      inputPlaceholder: "Digite o nome da nova pasta",
      showCancelButton: true,
    });

    if (folderName) {
      try {
        const newFolder = await createFolder(folderName);
        // Adiciona a nova pasta na lista local
        setFolders([...folders, newFolder]);
        Swal.fire("Sucesso", `A pasta "${newFolder.name}" foi criada!`, "success");
      } catch (error) {
        Swal.fire("Erro", "Ocorreu um erro ao criar a pasta.", "error");
      }
    }
  };

  const handleFolderSelection = (folderId) => {
    setSelectedFolderId(folderId);
    setCurrentPage(1);
    fetchData(1, folderId);
  };

  const handleMoveProject = async (projectId) => {
    const { value: folderId } = await Swal.fire({
      title: "Mover projeto para a pasta",
      input: "select",
      inputOptions: folders.reduce((acc, folder) => {
        acc[folder.id] = folder.name;
        return acc;
      }, {}),
      inputPlaceholder: "Selecione a pasta",
      showCancelButton: true,
    });

    if (folderId) {
      try {
        await moveProjectToFolder(projectId, folderId);
        // Atualiza o projeto na lista local
        const updatedProjects = projects.map((p) =>
          p.id === projectId ? { ...p, folder_id: folderId } : p
        );
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
        Swal.fire("Sucesso", "Projeto movido para a pasta!", "success");
      } catch (error) {
        Swal.fire("Erro", "Ocorreu um erro ao mover o projeto.", "error");
      }
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchTerm.length < 5) {
        setFeedbackMessage("Digite pelo menos 5 caracteres para realizar a busca.");
      } else {
        setFeedbackMessage("");
        fetchData(1, selectedFolderId, searchTerm);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex gap-4">
        {/* Skeleton para a listagem de pastas */}
        <div className="flex flex-col w-64 gap-4">
          <div className="h-6 mb-4 rounded-md bg-dark-200 animate-pulse"></div>
          <div className="flex flex-col gap-2">
            {/* Skeleton para cada item de pasta */}
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="flex items-center p-2 rounded-md bg-dark-200 animate-pulse"
              >
                <div className="w-6 h-6 mr-2 rounded-md bg-dark-150"></div>
                <div className="w-24 h-4 rounded-md bg-dark-150"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Skeleton para a listagem de projetos */}
        <div className="flex-1">
          <div className="flex items-center gap-4 mb-4">
            <div className="flex-1 h-10 rounded-md bg-dark-200 animate-pulse"></div>
            <div className="h-10 rounded-md w-36 bg-primary-600 animate-pulse"></div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="p-4 rounded-md shadow-md bg-dark-250">
                <div className="h-48 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
                <div className="h-6 mb-2 rounded-md bg-dark-200 animate-pulse"></div>
                <div className="h-4 rounded-md bg-dark-200 animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      {/* Listagem de pastas ao estilo Google Drive */}
      <div className="flex flex-col w-64 gap-4">
        <h3 className="text-lg font-bold text-light-0">Pastas</h3>

        <Button
          text="Criar Nova Pasta"
          type="button"
          className="text-center"
          size="small"
          variant="secundary"
          semibold
          full
          icon="plus"
          onClick={handleCreateFolder}
        />

        <div className="flex flex-col gap-2">
          {/* Opção para "Todas as Pastas" */}
          <div
            className={`flex items-center p-2 rounded-md cursor-pointer ${selectedFolderId === null ? 'bg-gray-600 bg-opacity-30 text-white' : 'bg-dark-150 text-light-0'
              }`}
            onClick={() => handleFolderSelection(null)}
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2 4a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
            </svg>
            Todas as Pastas
          </div>

          {/* Listagem de pastas */}
          {folders.map((folder) => (
            <div
              key={folder.id}
              className={`flex items-center p-2 rounded-md cursor-pointer ${selectedFolderId === folder.id ? 'bg-gray-600 bg-opacity-30 text-white' : 'bg-dark-150 text-light-0'
                }`}
              onClick={() => handleFolderSelection(folder.id)}
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                {selectedFolderId === folder.id ? (
                  <path d="M2 4a2 2 0 012-2h4a2 2 0 011.732 1H16a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
                ) : (
                  <path d="M2 4a2 2 0 012-2h4a2 2 0 011.732 1H16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
                )}
              </svg>
              {folder.name}
            </div>
          ))}
        </div>
      </div>

      {/* Listagem de projetos filtrados */}
      <div className="flex-1">
        <div className="flex items-center gap-4 mb-4">
          <input
            type="text"
            placeholder="Pesquisar em meus projetos..."
            value={searchTerm}
            onChange={(e) => {
              const value = e.target.value;
              setSearchTerm(value);
              if (value.length < 5) {
                setFeedbackMessage(`Digite mais ${5 - value.length} caracteres para buscar.`);
              } else {
                setFeedbackMessage("");
              }
            }}
            onKeyDown={handleSearchKeyDown}
            className="flex-1 p-2 border rounded-md border-dark-250 gray-600 bg-dark-250 text-light-0"
          />

          <Button
            text="Criar Novo Projeto"
            type="button"
            className="text-center"
            size="small"
            variant="primary"
            semibold
            icon="new-page"
            onClick={handleAddProject}
          />
        </div>
        {feedbackMessage && (
          <div className="mt-2 text-sm text-red-500">
            {feedbackMessage}
          </div>
        )}

        {Array.isArray(projects) && projects.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                className="p-4 rounded-md shadow-md cursor-pointer bg-dark-250"
                onClick={() => handleProjectClick(project.id)}
              >
                <img
                  src={project.thumbnail}
                  alt={project.title}
                  className="w-auto mx-auto mb-2 rounded-md max-h-48"
                />
                <h3 className="font-semibold text-md text-light-0">
                  {project.title}
                </h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMoveProject(project.id);
                  }}
                  className="text-sm text-blue-500"
                >
                  Mover para pasta
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center rounded-md bg-dark-250 text-light-500">
            Nenhum projeto encontrado.
          </div>
        )}

        <div className="flex justify-between mt-4">
          <Button
            text="Anterior"
            type="button"
            className="text-center"
            size="small"
            variant="primary"
            semibold
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          />
          <span>Página {currentPage} de {pagination.last_page}</span>
          <Button
            text="Próxima"
            type="button"
            className="text-center"
            size="small"
            variant="primary"
            semibold
            onClick={handleNextPage}
            disabled={currentPage === pagination.last_page}
          />
        </div>
      </div>
    </div>
  );
};
