import { useEffect, useState } from "react";
import { TextField } from "../../components/text-field";
import { Button } from "../../components/button";

import { useApi } from "../../hooks/useApi";
import PasswordLevel from "../../components/password-level";
import { ShieldCheck } from "../../components/icons/dark/shield-check";
import LogoCreativeRoxo from "../../assets/img/logos/braip-creative-horizontal-roxo.svg";

export const ResetPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordLevel, setPasswordLevel] = useState("");
  const [token, setToken] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState("");
  const [isDisabled] = useState(false);

  const { updatePassword, loading } = useApi();

  const validForm = () => {
    if (!password) {
      setErrorPassword("O campo é obrigatório.");
    }
    if (!passwordConfirm) {
      setErrorPasswordConfirm("O campo é obrigatório.");
    }
    if (password !== passwordConfirm) {
      setErrorPassword("As senhas não batem.");
      setErrorPasswordConfirm("As senhas não batem.");
    }
  };
  function checkPasswordLevel() {
    setPasswordLevel(0);

    const size = password.length;
    const lowercase = password.match(/[a-z]/gu)?.length || 0;
    const uppercase = password.match(/[A-Z]/gu)?.length || 0;
    const numbers = password.match(/[\d]/gu)?.length || 0;
    const symbols =
      password.match(
        /[\u00C0-\u017F!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~´ºª¨°¹²³ẽǘ£¢¬{}| ]/gu
      )?.length || 0;

    let total = 0;
    if (size >= 8) {
      total += 30;
    }
    if (lowercase) {
      total += lowercase >= 3 ? 15 : 10;
    }
    if (uppercase) {
      total += uppercase >= 2 ? 30 : 10;
    }
    if (numbers) {
      total += numbers >= 2 ? 30 : 10;
    }
    if (symbols) {
      total += symbols >= 2 ? 40 : 20;
    }
    if (!total) {
      setPasswordLevel(0);
    } else if (total <= 70) {
      setPasswordLevel(1);
    } else if (total <= 100) {
      setPasswordLevel(2);
    } else if (total <= 130) {
      setPasswordLevel(3);
    } else {
      setPasswordLevel(4);
    }
  }
  const handleSignIn = async (e) => {
    e.preventDefault();

    validForm();

    if (errorPassword || errorPasswordConfirm) return;

    updatePassword({ email: email.trim(), password, passwordConfirm, token })
      .then((res) => {
        setSubmitted(true);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    checkPasswordLevel();
  }, [password]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmail(params.get("email"));
    setToken(params.get("token"));
  }, []);

  return (
    <div className="flex flex-col min-h-full bg-top bg-no-repeat bg-cover bg-primary-600 bg-braip-login">
      <main className="flex flex-col items-center justify-center flex-1 min-w-fit">
        {!submitted ? (
          <form
            className="flex flex-col max-w-md gap-12 p-10 mx-4 bg-white rounded-sm mt-xxl"
            onSubmit={handleSignIn}
          >
            <div className="flex flex-col gap-14">
              <div className="flex items-center justify-between">
                <img
                  className="w-36"
                  src="/logos/braip-creative-horizontal-roxo.svg"
                  alt="Logo do Braip Canvas"
                />
              </div>

              <div class="flex flex-col gap-nano">
                <h1 class="text-dark-100 text-h7 font-poppins font-semibold">
                  Crie uma nova senha
                </h1>
                <p class="text-light-700 text-p4">
                  Agora é só criar uma senha segura para manter sua conta
                  protegida!
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-xs">
              <div className="flex flex-col gap-xxs">
                <div className="flex flex-col gap-xxxs">
                  <TextField
                    label="Nova senha"
                    id="password"
                    type="password"
                    value={password}
                    errorMessage={errorPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrorPassword("");
                    }}
                  />
                  <TextField
                    label="Confirmar nova senha"
                    id="password-confirm"
                    type="password"
                    value={passwordConfirm}
                    errorMessage={errorPasswordConfirm}
                    onChange={(e) => {
                      setPasswordConfirm(e.target.value);
                      setErrorPasswordConfirm("");
                    }}
                  />
                </div>
              </div>
              <PasswordLevel level={passwordLevel} />
              <div className="flex flex-col items-center gap-xs">
                <Button
                  text="Alterar minha senha"
                  type="submit"
                  className="text-center"
                  size="large"
                  semibold
                  full
                  disabled={isDisabled}
                  loading={loading["login"]}
                />
              </div>
            </div>
          </form>
        ) : (
          <div
            class="mx-4 mt-xxl flex max-w-md flex-col gap-12 rounded-sm bg-white p-10"
            v-else
          >
            <div class="flex flex-col gap-14">
              <div class="flex items-center justify-between">
                <img
                  className="w-36"
                  src="/logos/braip-creative-horizontal-roxo.svg"
                  alt="Logo do Braip Canvas"
                />
              </div>
              <div class="flex justify-center">
                <div class="p-6 bg-linear-purple">
                  <ShieldCheck class="fill-primary-600" size={50} />
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-xs">
              <div class="flex flex-col gap-nano">
                <h1 class="text-dark-100 text-h7 font-poppins font-semibold text-center">
                  Sua senha foi alterada com sucesso!
                </h1>
                <p class="text-light-700 text-p4 text-center">
                  Tudo pronto! Agora você já pode acessar a sua conta.
                </p>
              </div>
              <Button
                text="Acessar"
                class="!justify-center"
                size="large"
                semibold
                full
                to="/auth/sign-in"
              />
            </div>
          </div>
        )}
      </main>
      <footer className="flex justify-center w-full">
        <p className="mx-4 my-xs w-36 sm:w-full text-center text-p6 !text-light-0/40">
          © Braip 2024. Todos os direitos reservados
        </p>
      </footer>
    </div>
  );
};
