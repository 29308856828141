import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "../../components/text-field";
import { Button } from "../../components/button";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import localforage from "localforage";

import LogoCreativeRoxo from "../../assets/img/logos/braip-creative-horizontal-roxo.svg";

export const SignUp = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState("");

  const [isDisabled] = useState(false);

  const { register, loading } = useApi();
  const { setAuth } = useAuth();

  const validForm = () => {
    const emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (!name) {
      setErrorName("O campo é obrigatório.");
    }
    if (!email) {
      setErrorEmail("O campo é obrigatório.");
    }
    if (!emailRegex.test(email)) {
      setErrorEmail("O e-mail informado é inválido.");
    }
    if (!password) {
      setErrorPassword("O campo é obrigatório.");
    }
    if (!passwordConfirm) {
      setErrorPasswordConfirm("O campo é obrigatório.");
    }
    if (password !== passwordConfirm) {
      setErrorPassword("As senhas não batem.");
      setErrorPasswordConfirm("As senhas não batem.");
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    validForm();

    if (errorName || errorEmail || errorPassword || errorPasswordConfirm)
      return;

    register({ name, email: email.trim(), password, passwordConfirm })
      .then((res) => {
        if (res?.["access_token"]) {
          localforage.setItem("auth", res);
          setAuth({
            accessToken: res.access_token,
            tokenType: res.token_type,
            roles: res.roles,
          });
          navigate("/");
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="flex flex-col min-h-full bg-top bg-no-repeat bg-cover bg-primary-600 bg-braip-login">
      <main className="flex flex-col items-center justify-center flex-1 min-w-fit">
        <form
          className="flex flex-col max-w-md gap-12 p-10 mx-4 bg-white rounded-sm mt-xxl"
          onSubmit={handleSignIn}
        >
          <div className="flex flex-col gap-14">
            <div className="flex items-center justify-between">
              <img
                className="w-36"
                src="/logos/braip-creative-horizontal-roxo.svg"
                alt="Logo do Braip Canvas"
              />
            </div>

            <div className="flex flex-col gap-nano">
              <h1 className="font-semibold text-dark-100 text-h7 font-sora">
                Crie sua conta
              </h1>
              <p className="text-light-700 text-p4 text-ellipsis">
                Preencha com seus dados e comece a usar o Braip Canvas agora
                mesmo!
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-xs">
            <div className="flex flex-col gap-xxs">
              <div className="flex flex-col gap-xxxs">
                <TextField
                  label="Nome completo"
                  id="name"
                  type="text"
                  value={name}
                  errorMessage={errorName}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrorName("");
                  }}
                />
                <TextField
                  label="E-mail"
                  id="email"
                  type="text"
                  value={email}
                  errorMessage={errorEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorEmail("");
                  }}
                />
                <TextField
                  label="Senha"
                  id="password"
                  type="password"
                  value={password}
                  errorMessage={errorPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorPassword("");
                  }}
                />
                <TextField
                  label="Confirmar senha"
                  id="password-confirm"
                  type="password"
                  value={passwordConfirm}
                  errorMessage={errorPasswordConfirm}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value);
                    setErrorPasswordConfirm("");
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-center gap-xs">
              <Button
                text="Criar conta"
                type="submit"
                className="text-center"
                size="large"
                semibold
                full
                disabled={isDisabled}
                loading={loading["register"]}
              />
              <p className="font-normal text-p5 text-dark-1100">
                Já tem acesso?{" "}
                <Link
                  to="/auth/sign-in"
                  className="font-medium !text-primary-600 hover:!text-primary-600 underline"
                >
                  Entre por aqui!
                </Link>
              </p>
            </div>
          </div>
        </form>
      </main>
      <footer className="flex justify-center w-full">
        <p className="mx-4 my-xs w-36 sm:w-full text-center text-p6 !text-light-0/40">
          © Braip 2024. Todos os direitos reservados
        </p>
      </footer>
    </div>
  );
};
