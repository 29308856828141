import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import App from "../pages/App";
import { SignIn } from "../pages/auth/sign-in";
import { SignUp } from "../pages/auth/sign-up";
import { Home } from "../pages/home";
import { ForgotPassword } from "../pages/auth/forgot-password";
import { ResetPassword } from "../pages/auth/reset-password";
import { Error404 } from "../pages/error-404";
import { ProtectedRoute } from "./protected-route";
import { Tutorials } from "../pages/tutorials";
import { Projects } from "../pages/projects";

const RootLayout = ({ store }) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export const router = ({ store }) => {
  return createBrowserRouter([
    {
      path: "/",
      element: <RootLayout store={store} />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          ),
        },
        {
          path: "/projects",
          element: (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          ),
        },
        {
          path: "/app",
          element: (
            <ProtectedRoute>
              <App store={store} />
            </ProtectedRoute>
          ),
        },
        {
          path: "/tutorials", // Nova rota
          element: (
            <ProtectedRoute>
              <Tutorials />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: "/app/:designId",
        //   element: (
        //     <ProtectedRoute>
        //       <App store={store} />
        //     </ProtectedRoute>
        //   ),
        // },
        {
          path: "/auth/sign-in",
          element: <Navigate to="/sign-in" replace />,
        },
        {
          path: "/auth/sign-up",
          element: <Navigate to="/sign-up" replace />,
        },
        {
          path: "/sign-in",
          element: <SignIn />,
        },
        {
          path: "/sign-up",
          element: <SignUp />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "*",
          element: <Error404 />,
        },
      ],
    },
  ]);
};
